import { makeAutoObservable } from "mobx";
import * as api from "../api";
import AccountStore from "./account.store";

class UserStore {
    user = null;
    accounts = {};
    initializationPromise = null;
    primaryAccount = null;
    
    constructor() {
        makeAutoObservable(this);
    }

    setUser(user) {
        this.user = user;
    }

    // the user store is initialized with the accounts of the user once the user logs in
    async waitForInitialization() {
        while (!this.initializationPromise) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        return this.initializationPromise;
    }

    async getMarketplaceId() {
        await this.waitForInitialization();
        return this.primaryAccount.marketPlace._id;
    }

    async init() {
        if (this.initializationPromise) {
            return this.initializationPromise;
        }
        this.initializationPromise = api.getAccountsByJwt(this.user.token);
        const { data: accounts } = await this.initializationPromise;
        accounts.forEach(account => {
            this.accounts[account._id] = new AccountStore(account);
            if (!account.isSubAccount) {
                this.primaryAccount = account;
            } else {
                const {parent} = account
                const {marketPlace} = account
                parent.marketPlace = marketPlace
                this.primaryAccount = parent
            }
        })
    }
}

export default new UserStore();