import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Link } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/minimal/useOffSetTop';
import useResponsive from '../../hooks/minimal/useResponsive';
// utils
import { bgBlur } from '../../utils/minimal/cssStyles';
// config
import { HEADER } from '../../config-global';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/minimal/logo';
import Label from '../../components/minimal/label';
//
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { useLocales } from "../../locales";
import { Link as RouterLink } from "react-router-dom";
import { getMarketplaceLogo, isNimi, getMarketPlaceName } from "../../utils/market";
import { varFade, MotionContainer } from '../../components/minimal/animate';
import { m } from 'framer-motion';
import Iconify from '../../components/minimal/iconify';
import { useSettingsContext } from '../../components/minimal/settings';
// ----------------------------------------------------------------------
import { Stack } from '@mui/system';
import { default as DeskTopNavList } from './nav/desktop/NavList';
import { useParams } from 'react-router-dom';
import { useSellerPageContext } from '../../context-state/SellerPageContext';
import { useEventContext } from '../../context-state/EventContext';
import * as API from '../../api';
import SellerJumbotron from '../../pages/seller-public/SellerJumbotron';
import { getEndUserTokenKeyByHandler } from '../../utils/endUser';
import { useState } from 'react';
import _ from 'lodash';

export const SellerPublicPageHeader = () => {
    const { handler } = useParams();
    const { endUserContext, accountContext } = useSellerPageContext();
    const { endUser } = endUserContext;
    const { account } = accountContext;

    return (<>
        <EndUserHeader
            isAllowSeasonTicket={account?.isAllowSeasonTicket}
            sellerHandler={handler}
            endUser={endUser}
        />
        <SellerJumbotron />
    </>);
}

export const EventPageHeader = () => {
    const { eventPageContext } = useEventContext();
    const { endUser, setEndUser, account, } = eventPageContext;
    const isSeller = account.isSubAccount === false;
    const cachedSellerHandler = isSeller ? account?.handler : account?.parent?.handler;
    const [sellerHandler, setSellerHandler] = useState(cachedSellerHandler);

    const fetchData = async () => {
        try {
            localStorage.removeItem('endUserId')
            localStorage.removeItem('hrcCode')
            let seller;
            if (!cachedSellerHandler) {
                const { data } = await API.getSellerByIdPublic({ sellerId: account?.parent?._id });
                seller = data;
                if (!seller.handler) {
                    return; // fail silently we will not be able to fetch the end user
                }
                setSellerHandler(seller.handler);
            }
            const token = localStorage.getItem(getEndUserTokenKeyByHandler(cachedSellerHandler || seller?.handler));
            if (!token) {
                return; // fail silently we will not be able to fetch the end user
            }
            const res = await API.getEndUserDataByTokenAndSellerHandler({ token, sellerHandler: cachedSellerHandler || seller?.handler })
            setEndUser(res.data.endUser)
            eventPageContext.setInitialUserEventDetails(res.data.endUser)
            const subscriptions = res.data.seasonTickets.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            const hrc = res.data.endUser.selectedHrc || subscriptions[0]?.hrc || ""
            localStorage.setItem('endUserId', res.data.endUser._id)
            localStorage.setItem('hrcCode', hrc)
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    if (!sellerHandler) {
        return null; // there in no seller handler so nothing to show
    }

    return (<EndUserHeader
        isAllowSeasonTicket={account?.isAllowSeasonTicket}
        sellerHandler={sellerHandler}
        endUser={endUser}
    />);
}


export default function EndUserHeader({ sellerHandler, endUser, isAllowSeasonTicket }) {
    const carouselRef = useRef(null);
    const { translate } = useLocales();
    const theme = useTheme();

    const navConfig = [
        {
            title: 'events',
            path: `/seller/${sellerHandler}/events`,
        },
    ];

    if (isAllowSeasonTicket) {
        navConfig.push({
            title: 'subscriptions',
            path: `/seller/${sellerHandler}/subscriptions`,
        })
    }

    if (!endUser) {
        navConfig.push({
            title: 'signin',
            path: `/seller/${sellerHandler}/signin`,
        });
    } else {
        navConfig.push({
            title: 'my_account',
            path: `/seller/${sellerHandler}/my-account`,
        });
    }

    const isDesktop = useResponsive('up', 'md');
    const { themeDirection } = useSettingsContext();
    const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
    const isOffsetScreenHeight = useOffSetTop(600);
    const currentMarketplaceLogo = getMarketplaceLogo();
    const logoComponent = currentMarketplaceLogo ? <img src={currentMarketplaceLogo.path} style={{ width: currentMarketplaceLogo.width, height: currentMarketplaceLogo.height }} alt="" /> : <Logo />

    return (
        <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
            <Toolbar
                disableGutters
                sx={{
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_MAIN_DESKTOP,
                    },
                    transition: theme.transitions.create(['height', 'background-color'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.standard,
                    }),
                    ...(isOffset && {
                        ...bgBlur({ color: theme.palette.background.default }),
                        height: {
                            md: HEADER.H_MAIN_DESKTOP - 16,
                        },
                    }),
                }}
            >
                <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
                    {logoComponent}

                    <Box sx={{ flexGrow: 1 }} />

                    {isDesktop && <EndUserDesktopNav isOffset={isOffset} data={navConfig} />}

                    {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
                </Container>
            </Toolbar>

        </AppBar>
    );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
    sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
    return (
        <Box
            sx={{
                left: 0,
                right: 0,
                bottom: 0,
                height: 24,
                zIndex: -1,
                m: 'auto',
                borderRadius: '50%',
                position: 'absolute',
                width: `calc(100% - 48px)`,
                boxShadow: (theme) => theme.customShadows.z8,
                ...sx,
            }}
            {...other}
        />
    );
}



const EndUserDesktopNav = ({ isOffset, data }) => {
    return (
        <Stack component="nav" direction="row" spacing={5} sx={{ mr: 5, height: 1 }}>
            {data.map((link) => (
                <DeskTopNavList key={link.title} item={link} isOffset={isOffset} />
            ))}
        </Stack>
    );
}