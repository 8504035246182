import { useAuthContext } from "../../../context-state/AuthContext";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";
import { useSnackbar } from "../../../components/minimal/snackbar";
import { useEffect } from "react";
import myMembersStore from "../../../stores/myMembers.store";
import { observer } from "mobx-react-lite";

export const MyMembersGuard = observer(({ children }) => {

    const { getPrimaryAccount } = useAuthContext()
    const { enqueueSnackbar } = useSnackbar();
    const seller = getPrimaryAccount()

    useEffect(() => {
        if (myMembersStore.endUserSeasonTickets.length){
            return 
        }

        myMembersStore.init(seller.marketPlace._id, seller._id)
            .catch(error => {
                enqueueSnackbar('שגיאה בטעינת המנויים שלי', { variant: 'error' })
                console.error(error)
            })
    }, [])

    return (
        myMembersStore?.loading ? <LinearLoader /> : children
    )
})