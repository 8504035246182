import { useAuthContext } from "../../../context-state/AuthContext";
import { useDashboardContext } from "../../../context-state/DashboardContext";
import { getAccountEvents } from "../../../api";
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { sortEventsByStartData } from "../../../utils/sort";
import { LinearLoader } from "../../../components/linear-loader/LinearLoader";

// Fetch all events before continue to the events routes
export const AllEventsGuard = ({ refetch = [] }) => {
    const { account } = useAuthContext();
    const { eventContext } = useDashboardContext();
    const { setEvents } = eventContext;
    const [isFetched, setIsFetched] = useState(false);
    const location = useLocation();

    const getAllEvents = async () => {
        try {
            setIsFetched(false)
            const response = await getAccountEvents(account._id);
            setEvents(sortEventsByStartData(response.data));
        } catch (error) {
            console.error(error);
            setEvents([]);
        } finally {
            setIsFetched(true);
        }
    };

    useEffect(() => {
        getAllEvents();
    }, [location.pathname]);

    return (
        isFetched ? <Outlet /> : <LinearLoader />
    );
};
