import { APP_ENV, AWS_CLOUDFROND_BASE_URL } from "../config-global";
import { getPermissionToUploadFiles } from '../api/index'
import axios from 'axios'

export const S3Handler = {
    async uploadFile(file, contentType, fileName) {
        fileName = fileName || file.name || 'asset'
        contentType = contentType || file.type
        if (!contentType) {
            throw new Error('contentType is required please try a different file')
        }
        const fileKey = `${APP_ENV}_${Date.now()}_${fileName}`
        const { data } = await getPermissionToUploadFiles({ contentType, key: fileKey })

        await axios.put(data.accessUrl, file, {
            headers: {
                'Content-Type': contentType, // Make sure the content type matches the one in the URL parameters
            },
        });

        const fullPathToAsset = `${AWS_CLOUDFROND_BASE_URL}/${fileKey}`
        return fullPathToAsset
    },
    async uploadBase64(imageAsBase64) {
        const imageAsBlob = dataURItoBlob(imageAsBase64)
        return S3Handler.uploadFile(imageAsBlob)
    }
}

function dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}
