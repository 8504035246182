import { useSellerPageContext } from "../../context-state/SellerPageContext.jsx";
import useResponsive from "../../hooks/minimal/useResponsive.jsx";
import { Stack, Card } from '@mui/material'
import Image from "../../components/minimal/image/index.jsx";


const SellerJumbotron = () => {
    const { accountContext } = useSellerPageContext()
    const { account } = accountContext
    const isMobile = useResponsive('down', 'sm');

    if (!account) {
        return null
    }

    let { design } = account
    design = design ? design : {}

    return (
        <Stack sx={{ height: isMobile ? "40vh" : "50vh", position: "relative", width: "100%", backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${design.backgroundImage})`, backgroundPosition: "center", backgroundSize: "cover" }}>
            <Stack sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <Card sx={{ backgroundColor: "#fff", borderRadius: "50%" }}>
                    <Image
                        disabledEffect={true}
                        sx={{ width: isMobile ? "120px" : "200px", height: isMobile ? "120px" : "200px", borderRadius: "50%" }}
                        src={design.profileImage}
                    />
                </Card>
            </Stack>
        </Stack>
    )
}

export default SellerJumbotron