import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/minimal/loading-screen';
import { isMyKind } from "../utils/market";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    const loadingScreen = isMyKind() ? null : <LoadingScreen />
    return (
        <Suspense fallback={loadingScreen}>
            <Component {...props} />
        </Suspense>
    )
};

// ----------------------------------------------------------------------

// AUTH
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const AcceptInvitePage = Loadable(lazy(() => import('../pages/auth/AcceptInvitePage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));

// Event Page
export const EventPage = Loadable(lazy(() => import('../pages/event/EventPage')));
export const TicketPage = Loadable(lazy(() => import('../pages/ticket/TicketPage')));
export const SubscriptionPage = Loadable(lazy(() => import('../pages/subscription/SubscriptionPage')));
export const PublicEventsPage = Loadable(lazy(() => import('../pages/publicEvents/PublicEventsPage')));
export const PublicEventsTablePage = Loadable(lazy(() => import('../pages/publicEvents/PublicEventsTablePage')));
export const EventScannerPage = Loadable(lazy(() => import('../pages/EventScanner/EventScannerPage')));

// IFrames
export const TopUpPage = Loadable(lazy(() => import('../pages/topUp/TopUpPage')));



// DASHBOARD: Profile Settings / Admin
export const SeasonTicketPage = Loadable(lazy(() => import('../pages/dashboard/season-ticket/SeasonTicketPage.jsx')));
export const EndUsersPage = Loadable(lazy(() => import('../pages/dashboard/end-users/EndUsersPage.jsx')));
export const SingleEndUserPage = Loadable(lazy(() => import('../pages/dashboard/end-users/single-end-user/SingleEndUserPage.jsx')));
export const ProfileSettingsPage = Loadable(lazy(() => import('../pages/dashboard/profile-settings/ProfileSettingsPage')));
export const MySellersPage = Loadable(lazy(() => import('../pages/dashboard/my-sellers/MySellersPage')));
export const SeatsChartAdminPage = Loadable(lazy(() => import('../pages/dashboard/seats-chart/admin/SeatsChartAdminPage')));
export const MarketSaleStatsPage = Loadable(lazy(() => import('../pages/dashboard/admin/MarketSaleStatsPage')));
export const LegalPage = Loadable(lazy(() => import('../pages/dashboard/legal/LegalPage')));

// DASHBOARD: Collaborates
export const CollaboratesPage = Loadable(lazy(() => import('../pages/dashboard/collaborates/CollaboratesPage')));

// DASHBOARD: SubAccount
export const SubAccountsPage = Loadable(lazy(() => import('../pages/dashboard/sub-accounts/SubAccountsPage')));

// PARTNER: OnBoarding
export const PartnerOnBoardingPage = Loadable(lazy(() => import('../pages/auth/partners/PartnerOnBoardingPage')));

// DASHBOARD: Events
export const EventsPage = Loadable(lazy(() => import('../pages/dashboard/events/EventsPage')));
export const NewEventPage = Loadable(lazy(() => import('../pages/dashboard/events/NewEventPage')));
export const EditEventPage = Loadable(lazy(() => import('../pages/dashboard/edit-event/EditEventPage')));
export const SaleStatsPage = Loadable(lazy(() => import('../pages/dashboard/sales-stats/SaleStatsPage')));
export const CouponsPage = Loadable(lazy(() => import('../pages/dashboard/coupons/CouponsPage')));
export const SeasonTicketsDiscountPage = Loadable(lazy(() => import('../pages/dashboard/season-tickets-discount/SeasonTicketsDiscountPage')));
// export const LinksPage = Loadable(lazy(() => import('../pages/dashboard/links/LinksPage')));
export const OrdersPage = Loadable(lazy(() => import('../pages/dashboard/orders/OrdersPage')));
export const EventSettingsPage = Loadable(lazy(() => import('../pages/dashboard/settings/EventSettingsPage')));
export const SeatsChartPage = Loadable(lazy(() => import('../pages/dashboard/seats-chart/event/SeatsChartPage')));
export const TicketsPage = Loadable(lazy(() => import('../pages/dashboard/seats-chart/event/TicketsPage')));
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/general-app/GeneralAppPage')));
export const SalesTablePage = Loadable(lazy(() => import('../pages/dashboard/sales-table/SalesTablePage')));
export const CalendarPage = Loadable(lazy(() => import('../pages/dashboard/calendar/CalendarPage')));
export const PrivateClubPage = Loadable(lazy(() => import('../pages/dashboard/private-club/PrivateClubPage')));


// MAIN
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const MainPageHoc = Loadable(lazy(() => import('../pages/MainPageHoc')));
export const HomePage = Loadable(lazy(() => import('../pages/home/HomePage')));
export const SellerPublicPage = Loadable(lazy(() => import('../pages/seller-public/SellerPublicPage')));
export const SellerSubscriptionsPublicPage = Loadable(lazy(() => import('../pages/seller-public/SellerSubscriptionsPublicPage')));
export const SellerSubscriptionPurchase = Loadable(lazy(() => import('../pages/seller-public/SellerSubscriptionPurchase')));
export const SellerPublicPageMyAccount = Loadable(lazy(() => import('../pages/seller-public/SellerPublicPageMyAccount')));
export const SellerPublicPageSignIn = Loadable(lazy(() => import('../pages/seller-public/SellerPublicPageSignIn')));
export const SellerPublicPageSignUp = Loadable(lazy(() => import('../pages/seller-public/SellerPublicPageSignUp')));
export const MyMembersPage = Loadable(lazy(() => import('../pages/dashboard/my-members/MyMembersPage.jsx')));

export const SupportPage = Loadable(lazy(() => import('../pages/SupportPage.jsx')));
// MembershipClubMangmentPage
export const MembershipClubManagementPage = Loadable(lazy(() => import('../pages/membershipClubManagement/MembershipClubManagementPage.jsx')));


