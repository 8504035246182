import { LinearLoader } from "../../../components/linear-loader/LinearLoader.jsx";
import { useEffect } from "react";
import { useSellerPageContext } from "../../../context-state/SellerPageContext.jsx";
import { useNavigate } from "react-router-dom";
import { ErrorService } from "../../../services/errorService.js";
import * as API from "../../../api/index.js";
import { isFutureEvent } from "../../../utils/sort.js";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getEndUserTokenKeyByHandler } from "../../../utils/endUser.js";

export const SellerPublicPageGuard = ({ children }) => {

    const navigate = useNavigate();
    const navigateHome = () => navigate('/', { replace: true });
    const { accountContext, eventContext, subscriptionContext, endUserContext } = useSellerPageContext()
    const { setAccount } = accountContext
    const { endUser, setEndUser, setPurchasedSubscriptions, setPurchasedEvents, triggerEndUserDataFetch, fetchingEndUserData, setFetchingEndUserData } = endUserContext
    const { setEvents } = eventContext
    const { setSubscriptions } = subscriptionContext
    const [isLoading, setIsLoading] = useState(false)
    const { handler: sellerHandler } = useParams()

    const getPublicSellerPageData = async () => {
        if (!sellerHandler) {
            return
        }
        try {
            const { data } = await API.getAccountIdByHandler(sellerHandler)
            setAccount(data)
        } catch (e) {
            console.log(e);
            ErrorService.handleError(e)
            return
        }
    }

    const getEndUserData = async () => {
        const token = localStorage.getItem(getEndUserTokenKeyByHandler(sellerHandler))
        localStorage.removeItem('endUserId')
        localStorage.removeItem('hrcCode')
        if (!token) {
            return
        }

        try {
            setFetchingEndUserData(true)
            const { data } = await API.getEndUserDataByTokenAndSellerHandler({ token, sellerHandler })
            setEndUser(data.endUser)
            // sort the events by start date
            setPurchasedEvents(data.userEvents.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))
            // sort by startDate date
            const sortedSeasonTickets = data.seasonTickets.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            setPurchasedSubscriptions(sortedSeasonTickets)
            // for the event page
            const latestSubscription = sortedSeasonTickets[0]
            localStorage.setItem('endUserId', data.endUser._id)
            const hrc = data.endUser.selectedHrc || latestSubscription?.hrc || ""
            localStorage.setItem('hrcCode', hrc)
        } catch (e) {
            console.log(e);
        } finally {
            setFetchingEndUserData(false)
        }
    }

    useEffect(() => {
        const init = async () => {
            setIsLoading(true)
            await Promise.all([getPublicSellerPageData(), getEndUserData()])
            setIsLoading(false)
        }
        init()
    }, []);

    useEffect(() => {
        if (triggerEndUserDataFetch) {
            localStorage.setItem(getEndUserTokenKeyByHandler(sellerHandler), triggerEndUserDataFetch.token || "")
            getEndUserData()
        }

    }, [triggerEndUserDataFetch])

    return (
        !isLoading ? children : <LinearLoader />
    )
}
