import { format, getTime, formatDistanceToNow, differenceInYears, getHours, getMinutes } from 'date-fns';

// ----------------------------------------------------------------------
function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
}

export const isValidDate = (date) => {
    const timestamp = Date.parse(date);
    const isValid = Number.isNaN(timestamp) === false
    return isValid
}

export function fDate(date, newFormat) {
  if (!isValidDate(date)) {
        return '_'
  }
  const fm = newFormat || 'dd/MM/yy';
  const tz = convertTZ(date, 'Asia/Jerusalem')

  return date ? format(new Date(tz), fm) : '';
}

export function fDateTime(date, newFormat) {
    if (!isValidDate(date)) {
        return '_'
    }
  const fm = newFormat || 'dd/MM/yyyy kk:mm';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
    if (!isValidDate(date)) {
        return '_'
    }
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
    if (!isValidDate(date)) {
        return '_'
    }
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function calculateAge(date) {
    if (!isValidDate(date)) {
        return '_'
    }
    const age = differenceInYears(new Date(), new Date(date));
    return age;
}

export function isDateLessThanToday(data) {
    const dateToCheck = new Date(data)
    // set the time to the end of the day, so we can compare the day only
    dateToCheck.setHours(23, 59, 59, 999)
    const now = new Date()

    return dateToCheck.getTime() < now.getTime()
}

export function isDateGreaterThanToday(data) {
    const dateToCheck = new Date(data)
    const now = new Date()

    return dateToCheck.getTime() > now.getTime()
}

export const extractHoursAndMinutes = (date) => {
    const d = new Date(date)
    const hours = String(getHours(d))
    const minutes = String(getMinutes(d))
    const hoursFinal = hours.length === 1 ? `0${hours}` : hours
    const minutesFinal = minutes.length === 1 ? `0${minutes}` : minutes

    return `${hoursFinal}:${minutesFinal}`
}
