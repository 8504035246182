import { createContext, useContext, useState } from "react";

export const SellerPageContext = createContext(null);

export function SellerPageContextProvider({ children }) {

    const [account, setAccount] = useState(null)
    const [events, setEvents] = useState([])
    const [subscriptions, setSubscriptions] = useState([])
    const [endUser, setEndUser] = useState(null)
    const [purchasedSubscriptions, setPurchasedSubscriptions] = useState([])
    const [purchasedEvents, setPurchasedEvents] = useState([])
    const [triggerEndUserDataFetch, setTriggerEndUserDataFetch] = useState(null)
    const [fetchingEndUserData, setFetchingEndUserData] = useState(false) 

    return (
        <SellerPageContext.Provider
            value={{
                accountContext: { account, setAccount },
                eventContext: { events, setEvents },
                subscriptionContext: { subscriptions, setSubscriptions },
                endUserContext: { endUser, setEndUser, purchasedSubscriptions, setPurchasedSubscriptions, purchasedEvents, setPurchasedEvents, triggerEndUserDataFetch, setTriggerEndUserDataFetch, fetchingEndUserData, setFetchingEndUserData },
            }}
        >
            {children}
        </SellerPageContext.Provider>
    );
}


export const useSellerPageContext = () => {
    const context = useContext(SellerPageContext);

    if (!context) throw new Error('useSellerPageContext context must be use inside SellerPageContextProvider');

    return context;
};
