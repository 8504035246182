import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useCallback, useMemo, useContext } from 'react';
// utils
import axios from '../utils/minimal/axios';
import localStorageAvailable from '../utils/minimal/localStorageAvailable';
//
import { isValidToken, setSession } from '../auth/utils';
import { getAccount, getAccountById, getAccountByIdWithToken, getAccountsByJwt, logIn, verifyToken } from "../api";
import { useMarketplaceContext } from "./MarketplaceContext.jsx";
import * as API from "../api/index.js";
import userStore from "../stores/user.store.js";

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  account: null, // Chosen Account
  allAccounts: [] // The primary account & the sub accounts
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      account: action.payload.account,
      parentAccount: action.payload.parentAccount,
      allAccounts: action.payload.allAccounts || [],
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      account: action.payload.account,
      allAccounts: action.payload.allAccounts || [],
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      account: action.payload.account,
      allAccounts: action.payload.allAccounts || [],
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      account: null,
      allAccounts: []
    };
  }
  if (action.type === 'ADD_SUB_ACCOUNT') {
    return {
      ...state,
      allAccounts: [...state.allAccounts, action.payload.subAccount]
    };
  }
  if (action.type === 'SET_CHOSEN_ACCOUNT') {
    return {
      ...state,
      account: action.payload.account
    };
  }
  if (action.type === 'UPDATE_USER') {
    return {
      ...state,
      user: action.payload.user
    };
  }
  if (action.type === 'UPDATE_ALL_ACCOUNTS') {
    return {
      ...state,
      allAccounts: action.payload.allAccounts
    };
  }
  return state;
};


// This could be handled better some day.
export const isEventPage = () => {
  const [shouldBeEmptyString, shouldBeEvent] = window.location.pathname.split('/') // url.com/event
  return (shouldBeEmptyString === '' && shouldBeEvent === 'event')
}

export const isTicketPage = () => {
  const [shouldBeEmptyString, shouldBeTicket] = window.location.pathname.split('/') // url.com/ticket
  return (shouldBeEmptyString === '' && shouldBeTicket === 'ticket')
}

export const isSellerPage = () => {
  const [shouldBeEmptyString, shouldBeSeller] = window.location.pathname.split('/') // url.com/seller
  return (shouldBeEmptyString === '' && shouldBeSeller === 'seller')
}

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

const initialEmptyAuthPayload = {
  type: 'INITIAL',
  payload: {
    isAuthenticated: false,
    user: null,
    account: null,
    parentAccount: null,
    allAccounts: [],
  },
}

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const authenticate = async (accessToken, logInData) => {

  const userResponse = accessToken ? await verifyToken(accessToken) : await logIn(logInData)

  if (!userResponse?.data?.user) {
    console.log('no user?')
    return
  }

  const { user } = userResponse.data
  userStore.setUser(user)

  const newToken = user.token

  const { data } = await getAccountsByJwt(newToken);

  if (!data?.length) {
    console.log('no accounts?')
    return
  }
  await userStore.init()

  // Get all accounts with their parent accounts. the "primary" is the parent account of this user.
  let primaryAccount
  let marketplace
  const allAccounts = []

  for (const account of data) {
    if (account.isSubAccount) {
      allAccounts.push(account)
      continue
    }

    const primaryAccountFullData = await getAccountByIdWithToken(account._id, newToken)
    primaryAccount = primaryAccountFullData.data
    user.isSeller = primaryAccount?.owner === user._id
    allAccounts.push(primaryAccountFullData.data)

    marketplace = primaryAccount.marketPlace
    const marketplaceData = await API.getMarketplaceOfUser(newToken)
    if (marketplaceData.data?._id) {
      marketplace = marketplaceData.data
      user.isMarketPlaceOwner = true
    }

  }

  marketplace = marketplace ? marketplace : allAccounts?.[0].marketPlace
  return { token: newToken, account: primaryAccount, user, allAccounts, marketplace }
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setMarketplace } = useMarketplaceContext()

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {

      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
      if (!accessToken || !isValidToken(accessToken)) {
        dispatch(initialEmptyAuthPayload);
        return
      }

      const authData = await authenticate(accessToken)
      if (!authData) {
        dispatch(initialEmptyAuthPayload);
        return
      }

      const { user, account, token, allAccounts, marketplace } = authData

      /* on success initial auth */
      setSession(token);
      // TODO - do i need the 'parentAccount'?
      dispatch({ type: 'INITIAL', payload: { isAuthenticated: true, user, parentAccount: account, allAccounts }, });
      setMarketplace(marketplace)

    } catch (error) {
      console.error(error);
      setSession(null);
      dispatch(initialEmptyAuthPayload);
    }
  }, [storageAvailable]);

  useEffect(() => {
    if (!isEventPage() && !isTicketPage()) {
      initialize();
    }
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {

    const authData = await authenticate(undefined, { email, password })

    if (!authData) {
      dispatch(initialEmptyAuthPayload);
      return
    }

    const { user, account, token, allAccounts, marketplace } = authData
    setSession(token);
    setMarketplace(marketplace)
    dispatch({ type: 'LOGIN', payload: { user, account, allAccounts } });

  }, [])

  // REGISTER
  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // Add SubAccount
  const addSubAccount = (subAccount) => {
    dispatch({ type: 'ADD_SUB_ACCOUNT', payload: { subAccount } });
  }

  const setChosenAccount = (account) => {
    dispatch({ type: 'SET_CHOSEN_ACCOUNT', payload: { account } });
  }

  const updateUser = (user) => {
    dispatch({ type: 'UPDATE_USER', payload: { user } });
  }

  const getPrimaryAccount = () => {
    const primaryAccount = state.allAccounts.find(account => !account.isSubAccount) // seller
    if (!primaryAccount) {
      // so if we can't find the seller account, we will return the first account in the list
      // this means the user is an account collaborator
      const {parent} = state.allAccounts[0]
      const {marketPlace} = state.allAccounts[0]
      parent.marketPlace = marketPlace
      return parent
    }
    return primaryAccount
  }

  const updateAccount = (accountToUpdate) => {

    const newState = state.allAccounts.map(account => {
      if (account._id === accountToUpdate._id) {
        // Add more properties to update here...
        // account.name = accountToUpdate.name
        return {
          ...account,
          ...accountToUpdate
        }
      }
      return account
    })

    dispatch({ type: 'UPDATE_ALL_ACCOUNTS', payload: { allAccounts: newState } });
  }

  const getSellerPartnerPrimaryAccount = () => {
    const { user, allAccounts } = state
    return allAccounts.find(account => account.owner === user._id && !account.isSubAccount)
  }

  // This is the actual values which will be accessible
  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      account: state.account,
      allAccounts: state.allAccounts,
      addSubAccount,
      setChosenAccount,
      updateUser,
      method: 'jwt',
      login,
      register,
      logout,
      updateAccount,
      getPrimaryAccount,
      getSellerPartnerPrimaryAccount,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, state.account, state.allAccounts, login, logout, register, updateAccount, getPrimaryAccount, getSellerPartnerPrimaryAccount]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

// ----------------------------------------------------------------------

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuthContext context must be use inside AuthProvider');

  return context;
};

