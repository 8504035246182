// ----------------------------------------------------------------------

import { label } from "yet-another-react-lightbox/core";

const he = {
  hi: 'הי',
  type: 'סוג',
  event: 'אירוע',
  url: 'קישור',
  link: 'קישור',
  default: 'ברירת מחדל',
  html: 'HTML',
  cancel: 'ביטול',
  pdf: 'PDF',
  start_date: `תאריך התחלה`,
  end_date: `תאריך סיום`,
  'logout': 'התנתקות',
  not_logged_in: 'אתם לא מחוברים ',
  "entry-pass": 'כרטיסייה',
  "user-created": '  ידני ',
  "season-ticket-entry-pass": 'כרטיסייה',
  "season-ticket": 'מנוי',
  "booked_events": 'ההזמנות שלך',
  "my_subscriptions": 'המנויים שלך',
  financial: 'כספים',
  season_ticket: 'מנוי',
  manual_segment: 'ידני',
  event_segment: 'אירוע',
  finance_segment: 'כספים',
  activity: 'פעילות',
  hide: 'הסתר',
  verification_code: 'קוד אימות',
  male: `זכר`,
  female: `נקבה`,
  other: `אחר`,
  my_productions: `ההפקות שלי`,
  events: `אירועים`,
  system_users: `משתמשי הפקה`,
  switch: `החלף הפקה`,
  general_error: `ישנה תקלה, אנא נסו שנית`,
  general_success: `הפעולה בוצעה בהצלחה`,
  change_to_light_mode: `מעבר למצב בהיר`,
  change_to_dark_mode: `מעבר למצב חשוך`,
  random_image: `תמונה רנדומלית`,
  my_account: `המשתמש שלי`,
  join_date: `תאריך הצטרפות`,
  iterations_completed: `חיובים שבוצעו`,
  price: `מחיר`,
  phone: `טלפון`,
  fname: 'שם פרטי',
  lname: 'שם משפחה',
  email: 'אימייל',
  search: 'חיפוש',
  cancel_date: 'תאריך ביטול',
  created_at: 'תאריך הצטרפות',
  from: 'החל מ',
  to: 'עד ל',
  refresh: 'רענון',
  refresh_success: 'המידע עודכן בהצלחה',
  cancel_date_to: 'תאריך ביטול עד',
  cancel_date_from: 'תאריך ביטול מ',
  my_details: `הפרטים שלך`,
  show_more: `הצג עוד`,
  sms: `שליחת אסמסים`,
  sales_table: `טבלת מכירות`,
  my_members: `המנויים שלי`,
  coming_events: `אירועים קרובים`,
  terms_of_service: `תנאי שימוש`,
  privacy_policy: `מדיניות פרטיות`,
  name: `שם המנוי`,
  price_from: 'מחיר מינימום',
  price_to: 'מחיר מקסימום',
  status: 'סטטוס',
  date_from: 'החל מתאריך',
  date_to: 'עד לתאריך',
  all: 'הכל',
  active: 'פעיל',
  not_active: 'לא פעיל',
  roles: {
    all: 'כולם',
    scanner: 'סורק',
    trainer: 'מדריך',
    reader: 'צופה',
    editor: 'עורך',
    admin: 'מנהל',
    owner: 'owner',
  },
  header: {
    home: `עמוד הבית`,
    logout: `התנתקות`,
    login: `התחברות`,
    register: `הרשמה`,
    create_event: `יצירת אירוע`,
    book_demo: ` לחצו כאן לקביעת דמו`,
    signin: `כניסה`,
    my_account: `אזור אישי`,
    subscriptions: `מנויים`,
    events: `אירועים`,
  },
  NIMI: {
    home: {
      title: `מערכת מתקדמת למכירת כרטיסים וניהול קהילה`,
      subtitle: `אנחנו יודעים שבתור מפיק על, יש לך בקשות מיוחדות ואנחנו כאן כדי לענות על כל צורך שלך במהירות `,
      main_cta: `לקביעת דמו`
    },
  },
  MYKIND: {
    home: {
      title: `מכירת כרטיסים וניהול אירועים ללקוחות עם דרישות`,
      subtitle: `מענה מהיר גם בשעות העומס, בחירת מקומות והושבה כגון יציעים, סורק כרטיסים לאירועים ללא קליטה סלולרית`,
      main_cta: `התחל למכור`
    },
  },
  EASYTIX: {
    home: {
      title: ` מכירת כרטיסים וניהול אירועים ללקוחות עם דרישות מיוחדות`,
      subtitle: `כולל הושבה, אפליקציית סריקת כרטיסים וסטטיסטיקות מכירות`,
      main_cta: `התחל למכור`
    },
  },
  HORMESIS: {
    home: {
      title: ` מכירת כרטיסים וניהול אירועים ללקוחות עם דרישות מיוחדות`,
      subtitle: `כולל הושבה, אפליקציית סריקת כרטיסים וסטטיסטיקות מכירות`,
      main_cta: `התחל למכור`
    },
  },
  ZOA: {
    home: {
      title: ` מכירת כרטיסים וניהול אירועים ללקוחות עם דרישות מיוחדות`,
      subtitle: `כולל הושבה, אפליקציית סריקת כרטיסים וסטטיסטיקות מכירות`,
      main_cta: `התחל למכור`
    },
  },
  MINKOVSKY: {
    home: {
      title: ` מכירת כרטיסים וניהול אירועים ללקוחות עם דרישות מיוחדות`,
      subtitle: `כולל הושבה, אפליקציית סריקת כרטיסים וסטטיסטיקות מכירות`,
      main_cta: `התחל למכור`
    },
  },
  COMEDYCLUB: {
    home: {
      title: ` מכירת כרטיסים וניהול אירועים   `,
      subtitle: `כולל כרטיסייה, סריקת כרטיסים וסטטיסטיקות מכירות`,
      main_cta: `התחל למכור`
    },
  },
  RADICAL: {
    home: {
      title: ` מכירת כרטיסים וניהול אירועים ללקוחות עם דרישות מיוחדות`,
      subtitle: `כולל הושבה, אפליקציית סריקת כרטיסים וסטטיסטיקות מכירות`,
      main_cta: `התחל למכור`
    },
    terminology: {
      "event_page.season_ticket_discount_will_appear_after_choosing_tickets": `הנחת החברות תופיע בהמשך לאחר בחירת הכרטיסים`,
      "sales_table_head.season_tickets": `חברים`,
    },
  },
  ACCDIA: {
    home: {
      title: `VILLA`,
      main_cta: `Admin Login`,
      subtitle: `a place to be`,
    }
  },
  TARBUTA: {
    home: {
      title: ``,
      main_cta: `כניסה`,
      subtitle: `הרצאות מפי מיטב המרצים`,
    }
  },
  sales_table_head: {
    season_tickets: `מנויים`,
  },
  auth: {
    login: `התחברות`,
    new_password: `בחירת סיסמא חדשה`,
    approve: `אישור`,
    reset_password: `איפוס סיסמא`,
    send_reset_password: `שלח אימייל לאיפוס`,
    new_user: `עוד לא רשומים`,
    join_us: ` הצטרפו אלינו `,
    forgot_password: `שכחתי סיסמא`,
    login_title: `הי, ברוכים השבים!`,
    or: `או`,
    already_have_an_account: `כבר רשומים`,
    create_account: `צור חשבון`,
    wrong_credentials: `פרטים אינם נכונים`,
    been_invited: `הוזמנת ל`,
    already_exists_email: `האימייל כבר קיים`,
    invite_himself_error: `משתמש אינו יכול להזמין את עצמו`,
  },
  dashboard: {
    search: `חפש`,
    main: `ראשי`,
    selected: `נבחרו`,
    season_ticket_status: `סטטוס מנוי`,
    legal: {
      title: `הגדרת תקנון  `,
      terms_of_service: `תנאי שימוש`,
      privacy_policy: `מדיניות פרטיות`,
      submit: `שמור`,
      success: `הפרטים נשמרו בהצלחה`,
      error: `שגיאה בשמירת הפרטים`,
    },
    events: {
      choose_event_type: `בחרו סוג אירוע`,
      divided_order_approvable_not_allowed: `לא ניתן לאשר  הזמנה עם תשלום מפוצל לפני קבלת אישור תשלום `,
      handler_already_exists: `כתובת מקוצרת כבר קיימת נסו לבחור אחת שונה`,
      unauthorized: `אין לך הרשאה `,
      event: `אירוע `,
      new_event: `אירוע חדש`,
      edit_event: `עריכת אירוע`,
      my_events: `האירועים שלי`,
      create_new_event: `יצירת אירוע חדש`,
      event_name: `שם האירוע`,
      description: `תיאור`,
      content: `תוכן`,
      design: `עיצוב`,
      marketing: `שיווק`,
      embedding: `הטמעה`,
      embedding_explainer: `רוצים להוסיף משהו שאין לנו עדיין? ניתן להטמיע קוד כאן`,
      background_color: `צבע רקע`,
      buttons_color: `צבע כפתורים`,
      progress_bar_color: `צבע סרגל התקדמות`,
      privacy_policy_color: `צבע מדיניות פרטיות`,
      cover_photo: `תמונת רקע`,
      event_photo: `תמונת אירוע`,
      tickets: `כרטיסים`,
      seats_tickets: `כרטיסים למקומות מסומנים`,
      ticket_name: `שם כרטיס`,
      price: `מחיר`,
      amount: `כמות`,
      start_date: `תאריך התחלה`,
      end_date: `תאריך סיום`,
      location: `מיקום`,
      email_to_contact: `אימייל ליצירת קשר`,
      search: `חיפוש...`,
      tickets_sale_status: `סטטוס מכירת כרטיסים`,
      edit: `עריכה`,
      view: `תצוגה`,
      start_hour: `שעת התחלה`,
      end_hour: `שעת סיום`,
      active_from_date: `זמין מתאריך`,
      inactive_from_date: `זמין עד תאריך`,
      active: `פעיל`,
      active_in_main_page: `פעיל בדף הראשי`,
      inactive: `לא פעיל`,
      new_ticket: `כרטיס חדש`,
      duplicate: `שיכפול`,
      remove: `הסר`,
      event_handler: `כתובת מקוצרת`,
      preview: `תצוגה`,
      create_event: `יצירת אירוע`,
      required_field: `שדה זה הוא חובה`,
      invalid_form: `טופס אינו תקין`,
      english_letters_only: `אותיות באנגלית בלבד וללא רווחים`,
      invalid_email: `כתובת אימייל אינה תקינה`,
      all_orders: `כל ההזמנות`,
      waiting_for_approval: `הזמנות שממתינות לאישור`,
      rejected_orders: `הזמנות שנדחו`,
      approved_orders: `הזמנות שאושרו`,
      refunded_orders: `הזמנות שזוכו`,
      ordered_after_limit_reached: `הזמנות מאוחרות`,
      name_already_exists: `קיים כרטיס עם אותו שם`,
      no_events: `אין אירועים`,
      crete_event: `יצירת אירוע`,
      facebook_page: `עמוד פייסבוק`,
      facebook_pixel: `פייסבוק פיקסל`,
      facebook_pixel_explainer: `         נא להזין את ה ID בלבד  `,
      facebook_pixel_explainer_link: ` מה זה פייסבוק פיקסל?`,
      facebook_pixel_explainer_link_2: ` איך ליצור פייסבוק פיקסל?`,
      google_analytics_id: `גוגל אנליטיקס`,
      google_analytics_id_explainer: `        נא להזין את ה ID בלבד  `,
      google_analytics_id_explainer_link: `     מה זה גוגל אנליטיקס?`,
      google_analytics_id_explainer_link_2: ` איך ליצור מזהה גוגל אנליטיקס?`,
      orders: `הזמנות`,
      event_settings: `הגדרות אירוע`,
      order_name: `שם מזמין`,
      order_number: `מספר הזמנה`,
      order_status: `סטאטוס הזמנה`,
      user_event_status: `סטאטוס רוכש`,
      ticket_page_link: `לינק לכרטיס`,
      split_payment_link: `לינק לתשלום מחולק`,
      order_tickets_amount: `כמות כרטיסים`,
      order_tickets_quantity: `כמות כל הכרטיסים`,
      order_total_price: `סכום הזמנה`,
      coupon: `קופון`,
      season_ticket: `מנוי`,
      order_date: `תאריך ביצוע הזמנה`,
      order_details: `פרטי הזמנה`,
      event_user_full_name: `שם מלא`,
      seat: `מושב`,
      event_user_social: `קישור לסושיאל`,
      event_user_gender: `מין`,
      event_user_age: `גיל`,
      event_user_phone: `טלפון`,
      event_user_email: `אימייל`,
      event_user_ticket_name: `שם כרטיס`,
      event_user_paid_price: `סכום`,
      user_scanned: `כרטיס נסרק`,
      order_fully_refunded: `זיכוי מלא`,
      order_partial_refunded: `זיכוי חלקי`,
      cancellation_without_refund: `ביטול ללא זיכוי`,
      order_approved: `מאושר`,
      order_pending: `ממתין לאישור`,
      divide_order_pending: `תשלום מפוצל נוצר`,
      divide_order_active: `תשלום מפוצל בתהליך`,
      divide_order_complete: `תשלום מפוצל הושלם`,
      divide_order_failed: `תשלום מפוצל נכשל`,
      order_rejected: `ההזמנה נדחתה`,
      approve_order: `אישור הזמנה`,
      refund_order: `זיכוי הזמנה`,
      reject_order: `דחיית הזמנה`,
      open_whatsapp: `פתח בווטסאפ`,
      open_email: `פתח באימייל`,
      edit_details: `עריכת פרטים`,
      approve: `אישור`,
      cancel: `ביטול`,
      should_approve_order: `האם ברצונך לאשר את ההזמנה?`,
      should_reject_order: `האם ברצונך לדחות את ההזמנה?`,
      update_event: `עדכון אירוע`,
      order_approved_successfully: `ההזמנה אושרה בהצלחה!`,
      order_refunded_successfully: `ההזמנה זוכתה בהצלחה!`,
      no_data: `אין נתונים`,
      update: `עדכן`,
      copy: `העתק`,
      copied: `הועתק!`,
      question: `שאלה`,
      answer: `תשובה`,
      all_sales: `נתוני מכירות`,
      sales_per_ticket: `נתוני מכירות עבור כרטיס`,
      paid: `שולם`,
      total_approved: ` סה״כ כרטיסים שנקנו ומאושרים להגעה`,
      total_paid: ` סה״כ כרטיסים שנרכשו  `,
      total_refunded: ` סה״כ כרטיסים שזוכו`,
      total_coupons_used: ` סה״כ כרטיסים שנרכשו באמצעות קופון`,
      total_season_tickets_used: ` סה״כ כרטיסים שנרכשו באמצעות מנוי`,
      available: `זמין`,
      refunded: `זוכה`,
      tickets_sold: `כרטיסים נמכרו`,
      all_tickets: `כל הכרטיסים`,
      sold_out: `כל הכרטיסים נמכרו`,
      must_have_entering_q: `חייב שאלת כניסה`,
      active_event: `אירוע פעיל`,
      resend_mail: `שלח שוב מייל`,
      show_charges: `פרטי עסקה`,
      show_splited_charges: `עסקה מפוצלת`,
      send: `שלח`,
      should_resend_mail: `שלח מייל ל`,
      close: `סגור`,
      should_manual_approve: `חייב אישור ידני עבור כל הזמנה`,
      most_close_event: `הקרוב ביותר`,
      most_far_event: `הרחוק ביותר`,
      checked_in: `נסרק`,
      not_checked_in: `עדייו לא נסרק`,
      apply_check_in: `ביצוע סריקה ידנית`,
      email_resent: `אימייל נשלח!`,
      create_new_link: `לינק חדש`,
      delete_links: `מחיקת לינקים`,
      delete: `מחיקה`,
      link: `לינק`,
      change_status: `שינוי סטאטוס`,
      set_inactive: `הגדר כלא פעיל`,
      set_active: `הגדר כפעיל`,
      all_events: `כל האירועים`,
      future_events: `אירועים עתידים`,
      archive_events: `אירועי עבר`,
      past: `עבר`,
      send_refund: `שלח זיכוי`,
      charge_status_completed: `תשלום`,
      charge_status_refund: `זיכוי מלא`,
      charge_status_partial_refund: `זיכוי חלקי`,
      date: `תאריך`,
      card_mask: `מספר כרטיס`,
      buyer_email: `אימייל רוכש`,
      buyer_name: `שם רוכש`,
      buyer_phone: `טלפון רוכש`,
      card_brand: `סוג כרטיס`,
      charge_type: `סוג עסקה`,
      charge_sum: `סכום`,
      charge_external_code: `מספר עסקה`,
      external_transaction_auth_number: `מספר אישור סליקה`,
      max_capacity_explainer: `מקסימום כרטיסים שניתן למכור לארוע זה`,
      max_capacity: `קיבולת מקסימלית`,
      error_uploading_image: `שגיאה בהעלאת תמונה`,
    },
    sms_campaign: {
      file_extension_error: `סוג קובץ לא תקין`,
      name: `שם הקמפיין`,
      content: `תוכן הקמפיין`,
      phones: `מספרי טלפון`,
      edit: `ערוך`,
      properties: `מאפיינים`,
      recipients: `נמענים`,
      summary: `סיכום`,
      upload_phone_numbers_from_excel_file: `העלה מספרי טלפון מתוך אקסל`,
      sms_upload_support_files: '(csv, xslx)',
      upload_phones_numbers_manually: `העלה מספרי טלפון ידנית`,
      start_create_sms_campaign: 'התחל קמפיין של סמסים',
      sms_alert_duplicate_phone_numbers_are_not_allowed: 'וודא כי כל המספרים שונים זה מזה',
      sms_alert_please_provide_all_phone_numbers: 'וודא שאין תאים של מספרים ריקים',
      sms_alert_make_sure_that_no_number_has_not_been_added_to_the_list: 'וודא שאין מספרים שלא התווספו לרשימה או תאים ריקים',
      sms_alert_invalid_israeli_phone_numbers_provided: 'וודא כי המספרים הם בפורמט ישראל',
      sms_alert_chars_must_be_less_then_1000_characters: 'וודא כי הסמס לא מכיל יותר מ1000 תווים',
      sms_alert_add_phone_number: 'וודא כי הוספת את המספר: ',
      sms_campaign_content: 'תוכן הקמפיין',
      sms_campaign_name: 'שם הקמפיין',
      sms_campaign_counter_number: 'כמות שליחות',
      sms_campaign_created_at: 'תאריך שליחת הסמס',
      sms_excel_upload_example: 'דוגמאות לקבצי אקסל להעלאה',
      sms_excel_upload_first_file: 'דוגמא 1',
      sms_excel_upload_second_file: 'דוגמא 2',
      sms_excel_upload_third_file: 'דוגמא 2',
      sms_unable_to_add_phone_numbers: 'לא ניתן להוסיף מספרים נוספים'
    }
  },
  snackbar: {
    coupon_deleted_successfully: `הקופון נמחק בהצלחה!`,
    sub_account_updated_successfully: `הפקה עודכנה בהצלחה!`,
    sub_account_created_successfully: `הפקה נוצרה בהצלחה!`,
    event_created_successfully: `אירוע נוצר בהצלחה!`,
    event_updated_successfully: `אירוע עודכן בהצלחה!`,
    details_updated_successfully: `פרטים עודכנו בהצלחה!`,
    order_rejected: `ההזמנה נדחתה`,
    coupon_created_successfully: `קופון נוצר בהצלחה!`,
    coupon_updated_successfully: `קופון עודכן בהצלחה!`,
    season_ticket_discount_created_successfully: `הנחה נוצרה בהצלחה!`,
    season_ticket_discount_updated_successfully: `הנחה עודכנה בהצלחה!`,
    link_created_successfully: `לינק נוצר בהצלחה!`,
    link_updated_successfully: `לינק עודכנו בהצלחה!`,
    links_deleted_successfully: `לינקים נמחקו בהצלחה!`,
    invited_successfully: `משתמש הוזמן בהצלחה!`,
    collaborate_updated_successfully: `משתמש עודכן בהצלחה!`,
    event_handler_exists: `כתובת מקוצרת כבר קיימת`,
    refund_card_error: `ישנה בעיה עם חברת האשראי, אנא נסו שוב מאוחר יותר`,
    marketplace_account_created_successfully: `מוכר נוצר בהצלחה`,
    marketplace_account_updated_successfully: `מוכר עודכן בהצלחה`,
    tickets_price_updated_successfully: `מחירי הכרטיסים עודכנו בהצלחה!`,
    page_updated_successfully: `עמוד עודכן בהצלחה!`,
    event_settings_updated_successfully: `הגדרות האירוע עודכנו בהצלחה!`,
    price_level_removed_successfully: `רמת מחיר הוסרה בהצלחה!`,
  },
  event_page: {
    add_to_calendar: `הוספה ליומן`,
    chosen_ticket: `בחירת כרטיסים`,
    enter_details: `מילוי פרטים`,
    payment: `תשלום`,
    order_summary: `סיכום הזמנה`,
    total_to_pay: `סה״כ לתשלום`,
    male: `זכר`,
    female: `נקבה`,
    other: `אחר`,
    pay: `לתשלום`,
    complete_order: `סיום הזמנה`,
    split_order: `שלם עם חברים `,
    order_details: `פרטי המזמין`,
    ticket: `כרטיס`,
    first_name: `שם פרטי`,
    last_name: `שם משפחה`,
    phone: `טלפון`,
    email: `אימייל`,
    invalid_email: `אימייל אינו תקין`,
    invalid_phone: `מספר טלפון אינו תקין`,
    invalid_social_link: `קישור אינו תקין`,
    invalid_date: `תאריך אינו תקין (DD/MM/YYYY)`,
    birth_date: `תאריך לידה`,
    social_link: `קישור לעמוד אינסטגרם או פייסבוק`,
    credit_card_number: `מספר אשראי`,
    credit_card_date: `תוקף אשראי`,
    credit_card_cvv: `שלוש ספרות בגב הכרטיס`,
    credit_card_holder_name: `שם בעל הכרטיס`,
    pay_now: `תשלום`,
    required_field: `שדה זה הוא חובה`,
    to_order: `להזמנה`,
    out_of_order: `אזל`,
    please_choose_tickets: `אנא בחרו כרטיסים`,
    season_ticket_discount_will_appear_after_choosing_tickets: `*הנחת המנוי תופיע לאחר בחירת הכרטיס `,
    gender: `מין`,
    ticket_name: `שם כרטיס`,
    ticket_price: `מחיר`,
    season_ticket_discount_ticket_price: `מחיר למנויים`,
    ticket_amount: `כמות`,
    already_exists_email: `אנא מלאו אימייל שונה עבור כל כרטיס`,
    already_exists_phone: `אנא מלאו טלפון שונה עבור כל כרטיס`,
    already_exists_social: `אנא מלאו קישור שונה עבור כל כרטיס`,
    card_holder_first_name: `שם פרטי של בעל הכרטיס`,
    card_holder_last_name: `שם משפחה של בעל הכרטיס`,
    cvv_number: `ספרות בגב הכרטיס`,
    card_number: `מספר כרטיס`,
    card_date: `תוקף כרטיס`,
    generic_payment_form_error: `ישנה בעיה בטופס תשלום`,
    payment_rush_error_one: `המערכת מטפלת בבקשות קודמות, אנא המתינו`,
    payment_rush_error_two: `שניות ונסו שוב`,
    invalid_card_number: `מספר כרטיס אשראי אינו תקין`,
    invalid_card_cvv: `ספרות בגב הכרטיס אינן תקינות`,
    refusal_payment: `סירוב על ידי חברת האשראי, אנא נסו כרטיס אחר`,
    invalid_card_expiry: `תוקף כרטיס אינו תקין`,
    payment_invalid_phone_number: `נראה שיש בעיה עם מספר הטלפון שהוזן, אנא חזרו לשלב הקודם ובדקו את הפרטים`,
    payment_invalid_email: `נראה שיש בעיה עם האימייל שהוזן, אנא חזרו לשלב הקודם ובדקו את הפרטים`,
    seats_already_taken: `מצטערים, המושבים שנבחרו כבר נתפסו, נסו לבחר מושבים אחרים במקום `,
    missing_fields: `אנא ודאו שמילאתם את כל הפרטים`,
    order_success: `ההזמנה בוצעה בהצלחה!`,
    order_created_limit_reached: `הזמנה מחכה לאישור של מנהלי האירוע`,
    order_created_waiting_list: `לצערנו אזלו הכרטיסים  במהלך הקנייה. אבל… נכנסתם לרשימת המתנה`,
    new_order: `לביצוע הזמנה נוספת`,

    order_waiting_for_approval: `ההזמנה מחכה לאישור של מנהלי האירוע...`,
    ticket_by_mail: `פרטי ההזמנה ישלחו במייל`,
    form_error: `ישנה בעיה בטופס`,
    is_approved: `מאושר`,
    supported_payment_method: ` אמצעי תשלום נתמכים`,
    error_fallback: {
      title: ` אופס! משהו השתבש`,
      subtitle: `הצוות שלנו קיבל את המידע, ויטפל בבעיה בהקדם האפשרי`,
      action_btn_msg: `נסו שוב`,
    },
    payme_error: {
      title: `היתה בעיה בסליקת האשראי`,
      subtitle: `במידה והשתמשתם בכרטיסי אשראי מסוג דיינרס או אמריקן אקספרסס נסו להשתמש באמצעי תשלום חלופי`,
      action_btn_msg: `נסו שוב`,
    }
  },
  app: `דשבורד ראשי`,
  general: `כללי`,
  event_management: `ניהול אירוע`,
  event_sale_stats: `נתוני מכירות`,
  edit_event: `עריכת אירוע`,
  event_settings: `הגדרות`,
  seats_chart: `מפת מושבים`,
  seats_chart_admin: `ניהול מפות מושבים`,
  coupons: `קופונים`,
  tickets: `כרטיסים`,
  season_tickets_discount: ` הנחות מנויים`,
  event_orders: `הזמנות`,
  event_links: `לינקים`,
  my_events: `האירועים שלי`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  DAILY: `יומי`,
  WEEKLY: `שבועי`,
  MONTHLY: `חודשי`,
  YEARLY: `שנתי`,
  UNKNOWN: `לא ידוע`,
  EMAIL_MARKETING_CONSENT: {
    BASIC: `אישור קבלת תוכן שיווקי בדוא"ל`,
    SHORTER: "אני רוצה עדכונים למייל",
    LONGER: `אישור קבלת עדכונים, והצעות שיווקיות לאימייל `,
    COOLER: "עדכנו אותי על אירועים ומבצעים שווים!"
  },
  top_up: {
    title: `טעינת קרדיטים`,
    subtitle: `מה הסכום לטעינה?`,
    success: `הטעינה בוצעה בהצלחה!`,
    amount: `סכום הטעינה`,
    new_order: `לטעינה נוספת`,
    pay: `לתשלום`,
  },
  calendar_view: {
    event_title: 'כותרת האירוע',
    event_description: 'תיאור ',
    update_success: 'האירוע עודכן בהצלחה',
    create_success: 'האירוע נוצר בהצלחה',
    delete_success: 'האירוע נמחק בהצלחה',

    event_type: {
      table_reservation: "הזמנת שולחן",
      tour: "סיור",
      task: "משימה",
      regular: "רגיל",
      wellness: "בריאות",
      label: 'סוג אירוע'
    },
    set_event_type: "קבע סוג אירוע",
    edit_event: "ערוך אירוע",
    add_event: "הוסף אירוע",
    delete_event: "מחק אירוע",
    create_event: "צור אירוע",
    update_event: "עדכן אירוע",
    // New translations for EventType component
    tableReservation: {
      guestCount: "מספר אורחים",
      specialRequests: "בקשות מיוחדות",
      member: "חבר מועדון",
    },
    tour: {
      prospect: {
        firstName: "שם פרטי",
        lastName: "שם משפחה",
        phone: "טלפון"
      },
      tourGuide: "מדריך סיור",
      notes: "הערות"
    },
    task: {
      assignedTo: "מיועד ל",
      taskStatus: "סטטוס משימה",
      status: {
        pending: "ממתין",
        in_progress: "בתהליך",
        completed: "הושלם"
      }
    },
  },
};

export default he;