export const EventOrderStatuses = {
    PAID: 'paid',
    PENDING: 'pending',
    REJECTED: 'rejected',
    PARTIAL_REFUND: 'partial-refund',
    REFUNDED: 'refunded',
    LATE_ORDERS: 'lateOrders',
    CANCELlATION_WITHOUT_REFUND: 'cancellation_without_refund',
    DIVIDE_ORDER_PENDING: "divide_order_pending",
    DIVIDE_ORDER_ACTIVE: "divide_order_active",
    DIVIDE_ORDER_COMPLETE: "divide_order_complete",
    DIVIDE_ORDER_FAILED: "divide_order_failed"
}

export const CouponTypes = {
    FIXED: 'fixed',
    PERCENTAGE: 'percentage',
    CUSTOM_PRICE: "customPrice",
}

export const AppEnv = {
    PROD: 'live',
    STAGING: 'staging',
    LOCAL: 'local',
}

export const LinksUtm = {
    PRIMARY: "PRIMARY"
}

export const IncUtmActionTypes = {
    UNIQUE_VIEWS: "UNIQUE_VIEWS",
    VIEWS: "VIEWS",
    CLICKS: "CLICKS"
}

export const CollaboratesRoles = {
    SCANNER: "scanner",
    READER: "reader",
    TRAINER: "trainer",
    EDITOR: "editor",
    ADMIN: "admin",
}

export const AuthTypes = {
    LOGIN: 'login',
    REGISTER: 'register',
}

export const GeneralFieldsName = {
    B_DAY: 'birthday',
    L_NAME: 'lname',
    F_NAME: 'fname',
    EMAIL: 'email',
    PHONE: 'phone',
    GENDER: 'gender',
    SOCIAL_LINK: 'socialLink',
    EMAIL_MARKETING: 'isAllowMarketingDelivery',
}

export const FieldsTypes = {
    DATE_NO_TIME: 'date_no_time',
    STRING: 'string',
    YES_OR_NO: 'boolean',
    CHECKBOX: 'checkbox',
}

export const PaymeFileTypes = {
    SOCIAL_ID: 1,
    BANK_ACCOUNT_OWNERSHIP: 2,
    CORPORATE_CERTIFICATE: 3
}

export const FeeCollectionTypes = {
    PERCENTAGE_FROM_TICKET: "percentage_from_ticket",
    ON_TOP_OF_TICKET: "on_top_of_ticket"
}
