import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { sortEventsByStartData } from "../utils/sort";
import { extractId } from "../utils/transformers";

export const DashboardContext = createContext(null);

DashboardProvider.propTypes = {
    children: PropTypes.node,
};

export function DashboardProvider({ children }) {
    /* Highest state - events */
    const [events, setEvents] = useState(null)

    /* Each lower state is been hold as map of - { idOfTheEntity: theEntity }. this is the ONLY place which holds the entire entity, all others just map of pointers */
    const [ticketsMap, setTicketsMap] = useState({})
    const [userEventsMap, setUserEventsMap] = useState({})
    const [eventOrdersMap, setEventOrdersMap] = useState({})
    const [couponsMap, setCouponsMap] = useState({})
    const [linksMap, setLinksMap] = useState({})
    const [utmsMap, setUtmsMap] = useState({}) // this map is by 'utm' as the key this is our identifier from the 'link' entity
    const [chargesMap, setChargesMap] = useState({})

    /* Map relations, an object which maps higher entity to it relations - { idOfTheEntity: relatedEntitiesId[] }  */
    const [eventToTicketsMap, setEventToTicketsMap] = useState({})
    const [eventToUserEventsMap, setEventToUserEventsMap] = useState({})
    const [eventToEventOrdersMap, setEventToEventOrdersMap] = useState({})
    const [eventToCouponsMap, setEventToCouponsMap] = useState({})
    const [eventToLinksMap, setEventToLinksMap] = useState({})
    const [eventToUtmsMap, setEventToUtmsMap] = useState({})
    /* Map Relation to lower level entity */
    const [eventOrderToChargesMap, setEventOrderToChargesMap] = useState({})
    const [dashboardOverrideMsg, setDashboardOverrideMsg] = useState({})

    /* Event */
    const setNewEvent = (event) => setEvents(events ? [...events, event] : null)
    const updateEvent = (eventToUpdate) => {
        const newEventState = events.filter(event => event._id !== eventToUpdate._id)
        setEvents(sortEventsByStartData([...newEventState, eventToUpdate]))
    }
    const getEvent = (id) => events? events.find(event => event._id === id) : null

    /* Tickets */
    const updateTicket = (ticket) => {
        const newTicketsMap = JSON.parse(JSON.stringify(ticketsMap))
        newTicketsMap[ticket._id] = ticket
        setTicketsMap(newTicketsMap)
    }
    const updateManyTickets = (tickets) => {
        const newTicketsMap = JSON.parse(JSON.stringify(ticketsMap))
        tickets.forEach(ticket => { newTicketsMap[ticket._id] = ticket })
        setTicketsMap(newTicketsMap)
    }
    const updateEventToTicketsRelation = (eventId, tickets) => {
        const ids = tickets.map(extractId)
        const newEventToTicketsMap = JSON.parse(JSON.stringify(eventToTicketsMap))
        const newArray = newEventToTicketsMap[eventId]?.length ? [...newEventToTicketsMap[eventId], ...ids] : ids
        newEventToTicketsMap[eventId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventToTicketsMap(newEventToTicketsMap)
    }
    const getTicket = (id) => ticketsMap[id]
    const getTicketsOfEvent = (eventId) => eventToTicketsMap[eventId]
    const removeTicketsFromMap = (ids) => {
        const newTicketsMap = JSON.parse(JSON.stringify(ticketsMap))
        ids.forEach(id => delete newTicketsMap[id])
        setTicketsMap(newTicketsMap)
    }
    const removeTicketsFromRelation = (eventId, ticketIdsToRemove) => {
        const newEventToTicketsMap = JSON.parse(JSON.stringify(eventToTicketsMap))
        newEventToTicketsMap[eventId] = newEventToTicketsMap[eventId].filter(ticketId => !ticketIdsToRemove.includes(ticketId))
        newEventToTicketsMap[eventId] = [ ...new Set(newEventToTicketsMap[eventId])] // Force to remove duplications
        setEventToTicketsMap(newEventToTicketsMap)
    }

    /* UserEvent */
    const updateUserEvent = (userEvent) => {
        const newUserEventsMap = JSON.parse(JSON.stringify(userEventsMap))
        newUserEventsMap[userEvent._id] = userEvent
        setUserEventsMap(newUserEventsMap)
    }
    const updateManyUserEvents = (userEvents) => {
        const newUserEventsMap = JSON.parse(JSON.stringify(userEventsMap))
        userEvents.forEach(userEvent => { newUserEventsMap[userEvent._id] = userEvent })
        setUserEventsMap(newUserEventsMap)
    }
    const updateEventToUserEventRelation = (eventId, userEvents) => {
        const ids = userEvents.map(extractId)
        const newEventToUserEventsMap = JSON.parse(JSON.stringify(eventToUserEventsMap))
        const newArray = newEventToUserEventsMap[eventId]?.length ? [...newEventToUserEventsMap[eventId], ...ids] : ids
        newEventToUserEventsMap[eventId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventToUserEventsMap(newEventToUserEventsMap)
    }
    const getUserEvent = (id) => userEventsMap[id]
    const getUserEventsOfEvent = (eventId) => eventToUserEventsMap[eventId]

    /* EventOrder */
    const updateEventOrder = (eventOrder) => {
        const newEventOrdersMap = JSON.parse(JSON.stringify(eventOrdersMap))
        newEventOrdersMap[eventOrder._id] = eventOrder
        setEventOrdersMap(newEventOrdersMap)
    }
    const updateManyEventOrders = (eventOrders) => {
        const newEventOrdersMap = JSON.parse(JSON.stringify(eventOrdersMap))
        eventOrders.forEach(eventOrder => { newEventOrdersMap[eventOrder._id] = eventOrder })
        setEventOrdersMap(newEventOrdersMap)
    }
    const updateEventToEventOrdersRelation = (eventId, eventOrders) => {
        const ids = eventOrders.map(extractId)
        const newEventToEventOrdersMap = JSON.parse(JSON.stringify(eventToEventOrdersMap))
        const newArray = newEventToEventOrdersMap[eventId]?.length ? [...newEventToEventOrdersMap[eventId], ...ids] : ids
        newEventToEventOrdersMap[eventId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventToEventOrdersMap(newEventToEventOrdersMap)
    }
    const getEventOrder = (id) => eventOrdersMap[id]
    const getEventOrdersOfEvent = (id) =>  eventToEventOrdersMap[id]

    /* Coupons */
    const updateCoupon = (coupon) => {
        const newCouponsMap = JSON.parse(JSON.stringify(couponsMap))
        newCouponsMap[coupon._id] = coupon
        setCouponsMap(newCouponsMap)
    }
    const updateManyCoupons = (coupons) => {
        const newCouponsMap = JSON.parse(JSON.stringify(couponsMap))
        coupons.forEach(coupon => { newCouponsMap[coupon._id] = coupon })
        setCouponsMap(newCouponsMap)
    }
    const updateEventToCouponsRelation = (eventId, coupons) => {
        const ids = coupons.map(extractId)
        const newEventToCouponsMap = JSON.parse(JSON.stringify(eventToCouponsMap))
        const newArray = newEventToCouponsMap[eventId]?.length ? [...newEventToCouponsMap[eventId], ...ids] : ids
        newEventToCouponsMap[eventId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventToCouponsMap(newEventToCouponsMap)
    }
    const getCoupon = (id) => couponsMap[id]
    const getCouponsOfEvent = (id) =>  eventToCouponsMap[id]

    /* Links */
    const updateLink = (link) => {
        const newLinksMap = JSON.parse(JSON.stringify(linksMap))
        newLinksMap[link._id] = link
        setLinksMap(newLinksMap)
    }
    const updateManyLinks = (links) => {
        const newLinksMap = JSON.parse(JSON.stringify(linksMap))
        links.forEach(link => { newLinksMap[link._id] = link })
        setLinksMap(newLinksMap)
    }
    const updateEventToLinksRelation = (eventId, links) => {
        const ids = links.map(extractId)
        const newEventToLinksMap = JSON.parse(JSON.stringify(eventToLinksMap))
        const newArray = newEventToLinksMap[eventId]?.length ? [...newEventToLinksMap[eventId], ...ids] : ids
        newEventToLinksMap[eventId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventToLinksMap(newEventToLinksMap)
    }
    const getLink = (id) => linksMap[id]
    const getLinksOfEvent = (id) => eventToLinksMap[id]

    const deleteLinks = (linksIds) => {
        const newLinksMap = JSON.parse(JSON.stringify(linksMap))
        for (const id of linksIds) {
            delete newLinksMap[id]
        }
        setLinksMap(newLinksMap)
    }

    const deleteEventToLinksRelation = (eventId, linksIds) => {
        const newEventToLinksMap = JSON.parse(JSON.stringify(eventToLinksMap))
        for (const id of linksIds) {
            newEventToLinksMap[eventId] = newEventToLinksMap[eventId].filter(linkId => linkId != id)
        }
        setEventToLinksMap(newEventToLinksMap)
    }

    /* Utms */
    const updateManyUtms = (utms) => {
        const newUtmsMap = JSON.parse(JSON.stringify(utmsMap))
        utms.forEach(utm => { newUtmsMap[utm.utm] = utm })
        setUtmsMap(newUtmsMap)
    }
    const updateEventToUtmsRelation = (eventId, utms) => {
        const utmsSymbol = utms.map(utm => utm.utm)
        const newEventToUtmsMap = JSON.parse(JSON.stringify(eventToUtmsMap))
        const newArray = newEventToUtmsMap[eventId]?.length ? [...newEventToUtmsMap[eventId], ...utmsSymbol] : utmsSymbol
        newEventToUtmsMap[eventId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventToUtmsMap(newEventToUtmsMap)
    }
    const getUtm = (utm) => utmsMap[utm]
    const getUtmsOfEvent = (id) => eventToUtmsMap[id]

    /* Charges */
    const updateCharge = (charge) => {
        const newChargesMap = JSON.parse(JSON.stringify(chargesMap))
        newChargesMap[charge._id] = charge
        setChargesMap(newChargesMap)
    }
    const updateManyCharge = (charges) => {
        const newChargesMap = JSON.parse(JSON.stringify(chargesMap))
        charges.forEach(charge => { newChargesMap[charge._id] = charge })
        setChargesMap(newChargesMap)
    }
    const updateEventOrderToChargesRelation = (eventOrderId, charges) => {
        const ids = charges.map(extractId)
        const newEventOrderToChargesMap = JSON.parse(JSON.stringify(eventOrderToChargesMap))
        const newArray = newEventOrderToChargesMap[eventOrderId]?.length ? [...newEventOrderToChargesMap[eventOrderId], ...ids] : ids
        newEventOrderToChargesMap[eventOrderId] = [ ...new Set(newArray)] // Force to remove duplications
        setEventOrderToChargesMap(newEventOrderToChargesMap)
    }
    const getCharge = (id) => chargesMap[id]
    const getChargesOfEventOrder = (eventOrderId) => eventOrderToChargesMap[eventOrderId]
    const clearEventOrderToChargesRelation = (eventOrderId) => {
        const newEventOrderToChargesMap = JSON.parse(JSON.stringify(eventOrderToChargesMap))
        delete newEventOrderToChargesMap[eventOrderId]
        setEventOrderToChargesMap(newEventOrderToChargesMap)
    }

    // Use for debug
    window.printState = () => {
        console.log(" -------- events -------- ");
        console.log(events);
        console.log(" -------- tickets -------- ");
        console.log(ticketsMap);
        console.log(" -------- userEvents -------- ");
        console.log(userEventsMap);
        console.log(" -------- eventOrders -------- ");
        console.log(eventOrdersMap);
        console.log(" -------- coupons -------- ");
        console.log(couponsMap);
        console.log(" -------- links -------- ");
        console.log(linksMap);
    }
    window.printStateRelations = () => {
        console.log(" -------- tickets relation -------- ");
        console.log(eventToTicketsMap);
        console.log(" -------- userEvents relation -------- ");
        console.log(eventToUserEventsMap);
        console.log(" -------- eventOrders relation -------- ");
        console.log(eventToEventOrdersMap);
        console.log(" -------- coupons relation -------- ");
        console.log(eventToCouponsMap);
        console.log(" -------- links relation -------- ");
        console.log(eventToLinksMap);
    }

    return (
        <DashboardContext.Provider
            value={{
                eventContext: { events, setEvents, getEvent, setNewEvent, updateEvent },
                eventOrderContext: { updateEventOrder, updateManyEventOrders, updateEventToEventOrdersRelation, getEventOrder, getEventOrdersOfEvent },
                userEventContext: { updateUserEvent, updateManyUserEvents, updateEventToUserEventRelation, getUserEvent, getUserEventsOfEvent },
                ticketContext: { updateTicket, updateManyTickets, updateEventToTicketsRelation, getTicket, getTicketsOfEvent, removeTicketsFromMap, removeTicketsFromRelation },
                couponContext: { updateCoupon, updateManyCoupons, updateEventToCouponsRelation, getCoupon, getCouponsOfEvent },
                utmContext: { updateManyUtms, updateEventToUtmsRelation, getUtm, getUtmsOfEvent },
                chargeContext: { updateCharge, updateManyCharge, updateEventOrderToChargesRelation, getCharge, getChargesOfEventOrder, clearEventOrderToChargesRelation },
                linkContext: { updateLink, updateManyLinks, updateEventToLinksRelation, getLink, getLinksOfEvent, deleteLinks, deleteEventToLinksRelation }, // not really in use currently
                overrideMsgContext: { dashboardOverrideMsg, setDashboardOverrideMsg }
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
}

export const useDashboardContext = () => {
    const context = useContext(DashboardContext);

    if (!context) throw new Error('useDashboardContext context must be use inside DashboardProvider');

    return context;
};
